import { useProductsStore } from '~/store/productsStore.js'
import { SHOP_ROUTES_NAMES } from '~/constants/index'

const productStore = useProductsStore()
const { routeName } = useShopRouteName()

// child of shopDiamond middleware in legacy code
// use for item-diamond-details, item-diamond, diamond, diamond-details
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!process.client) return

  if (to.query.diamond) {
    if (routeName.value !== SHOP_ROUTES_NAMES.DIAMOND_DETAILS) productStore.getSelectedDiamonds(to.query)

    if (from.query.step !== from.query.step) {
      let stone

      if (to.query.stoneType == 2) {
        stone = 'gemstone'
      } else if (to.query.diamondType == -3) {
        stone = 'coloured-diamond'
      } else {
        stone = 'diamond'
      }

      ga_event_flow(stone)
    }
  }
})
